@page {
    size: auto;
    margin: 20mm;
}

@media print {
    .table {
        page-break-inside: avoid !important;
        page-break-before: auto !important;
    }

    /* .pagebreak {
        display: block;
        page-break-inside: avoid !important;
        page-break-before: auto !important;
    } */

    html, body {
        height: initial !important;
        overflow: initial !important;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }
}

.table {
    color: #000000;
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #000000;
    text-align: center;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #000000;
}

.table tbody + tbody {
    border-top: 2px solid #000000;
}

.table .table {
    background-color: #000000;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #000000;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #000000;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 1px;
}


@media screen {
    .answerSheet {
        display: none;
    }
}

.pubish-quiz-header {
    font-weight: 100;
    color: #71777c;
}